import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Slide from "react-awesome-reveal";
import i18next from "i18next";
import { withTranslation } from 'react-i18next';

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    const { t } = this.props;

    /*
    const networks = this.props.data.social.filter(network => network.url !== "").map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });
    */

    var currentLng = i18next.language;
    console.info("current lang: " + currentLng);
    const languageList = [
      { key: "en", label: "English"},
      { key: "de", label: "Deutsch"}
    ];

    const languages = languageList.map(function(lng) {
      return (
        <li key={lng.key}>
          <a href="/" onClick={() => {
                  i18next.changeLanguage(lng.key);
                }}>
            {lng.label}
          </a>
        </li>
      );
    });

    return (
      <footer>
        <div className="row">
          <Slide left duration={1000} triggerOnce={true}>
            <div className="twelve columns">
              
              <ul className="language-links">
                <li>{t("main.language")}</li>
                {languages}
              </ul>
            </div>
          </Slide>
        </div>
        <div className="row">
          <Slide left duration={1000} triggerOnce={true}>
            <div className="twelve columns">
           
              {/* <ul className="social-links">{networks}</ul>*/}
        
              <ul className="copyright">
                <li>&copy; Copyright {new Date().getFullYear()} Maximilian Aster</li>
                <li>
                  <Link to={'/impressum'}>Impressum</Link>
                </li>
              </ul>
            </div>
          </Slide>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
