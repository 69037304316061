import React, { Component } from "react";
import Slide from "react-awesome-reveal";
import { withTranslation } from 'react-i18next';

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const { t } = this.props;

    return (
      <section id="about">
        <Slide left duration={1000} triggerOnce={true}>

          <div className="row">
            <div className="three columns">
              <div className="quote-text">"{t("about.quote-text")}"</div>
              <div className="quote-author">({t("about.quote-author")})</div>
            </div>
            <div className="six columns main-col">
              <h2>{t("about.title")}</h2><br/>
              <p>{t("about.experience-text")}</p>
              <p>{t("about.development-text")}</p>
              <p>{t("about.architecture-text")}</p>
              <p>{t("about.methodology-text")}</p>
            </div>
            <div className="three columns">
              <div className="certificate-row">
                <p>Certified Scrum Master</p>
                <img
                  src="images/about/scrum_master_certificate.png"
                  alt="scrum"
                  className="about-pic"
                />
              </div>
              <div className="certificate-row">
                <p>TOGAF 9 Certified</p>
                <img
                  src="images/about/togaf9_certificate.png"
                  alt="togaf"
                  className="about-pic"
                />
              </div>
              <div className="certificate-row">
                <p>Machine Learning Specialization</p>
                <img
                  src="images/about/deeplearning_ai_certificate.png"
                  alt="deeplearning"
                  style={{ borderRadius: "8px"}}
                  className="about-pic"
                />
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default withTranslation()(About);