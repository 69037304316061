import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import "./App.css";
import Home from "./Pages/Home";
import Impressum from "./Pages/Impressum";

import ScrollToTop from "./Utils/ScrollToTop"

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='/impressum' element={<Impressum />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
