import React, { Component } from "react";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import About from "../Components/About";
/*import Resume from "../Components/Resume";*/
import Contact from "../Components/Contact";
import Services from "../Components/Services";
/*import Portfolio from "../Components/Portfolio";*/
/*import Portfolio from "../Components/Testimonials";*/

import {getContentData} from "../Utils/DataUtils"

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    getContentData(function(data) {
        // This will trigger a new render
        this.setState({ contentData: data });
      }.bind(this));
  }

  render() {
    // content-data initially not yet loaded
    if (!this.state.contentData) return null;

    return (
      <div className="Home">
        <Header data={this.state.contentData} />
        <Services data={this.state.contentData} />
        <About data={this.state.contentData} />
        {/*<Resume data={this.state.contentData.resume} />*/}
        {/*<Portfolio data={this.state.contentData.portfolio} />*/}
        {/*<Testimonials data={this.state.contentData.testimonials} />*/}
        <Contact data={this.state.contentData} />
        <Footer data={this.state.contentData} />
      </div>
    );
  }
}

export default Home;
