import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Slide from "react-awesome-reveal";
import { withTranslation } from 'react-i18next';

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const { t } = this.props;

    const colors = ["#4b69ab", "#3cb8ba", "#9c9c9c"];
    const num = window.innerWidth / 15;

    return (
      <header id="home">
        <ParticlesBg type="cobweb" color={colors} bg={{position: "absolute", zIndex: -1, top: 0, left: 0, backgroundColor: "white"}} num={num}/>

      {/* Remove className to have transparent navigation during "Home", see also fading in init.js */}
        <nav id="nav-wrap" className="opaque opacity-75">
          <a className="mobile-btn" href="#nav-wrap">
            {t("header.show-navigation")}
          </a>
          <a className="mobile-btn" href="#home">
            {t("header.hide-navigation")}
          </a>

          <ul id="nav" className="nav">

            <li className="current">
              <a className="smoothscroll" href="#home">
                {t("header.home")}
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#services">
                {t("header.services")}
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#about">
                {t("header.about")}
              </a>
            </li>
            {/*
            <li>
              <a className="smoothscroll" href="#resume">
                Resume
              </a>
            </li>
            */}
            {/*
            <li>
              <a className="smoothscroll" href="#portfolio">
                Works
              </a>
            </li>
            */}
            <li>
              <a className="smoothscroll" href="#contact">
                {t("header.contact")}
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Slide left duration={1000} triggerOnce={true}>
              <img src="images/edevity-logo-with-text.svg" style={{ height: "120px"}} alt="Logo"/>
              <h1 className="responsive-headline">{t("main.name")}</h1>
            </Slide>
            <Slide left duration={1000} triggerOnce={true}>
              <h3>{t("main.description")}</h3>
            </Slide>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default withTranslation()(Header);
