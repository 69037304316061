import React, { Component } from "react";
import { Slide } from "react-awesome-reveal";
import { withTranslation } from 'react-i18next';

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="contact">
        <Slide left duration={1000} triggerOnce={true}>

          <div className="row">
            <div className="three columns">
              <h1>
                <span></span>
              </h1>
            </div>
            <div className="six columns main-col">
              <p><a className="contact-entry" href="https://www.linkedin.com/in/maximilian-aster/">LinkedIn</a></p>
              <p><a className="contact-entry" href="mailto:maximilian.aster@edevity.com">E-Mail</a></p>
            </div>
            <div className="three columns">
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default withTranslation()(Contact);