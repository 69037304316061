import React, { Component } from "react";
import Slide from "react-awesome-reveal";
import { withTranslation } from 'react-i18next';

class Services extends Component {
  render() {
    if (!this.props.data) return null;

    const { t } = this.props;

    return (
      <section id="services">
        <Slide left duration={1000} triggerOnce={true}>
          <div className="row">
            <div className="four columns">
              <div>
                <img
                  className="services-pic"
                  src="images/services/development.jpg"
                  alt="Development"
                />
              </div>
              <h3>{t("services.development-title")}</h3>
              <p>{t("services.development-desc")}</p>
            </div>
            <div className="four columns">
              <div>
                <img
                  className="services-pic"
                  src="images/services/architecture.jpg"
                  alt="Architecture"
                />
              </div>
              <h3>{t("services.architecture-title")}</h3>
              <p>{t("services.architecture-desc")}</p>
            </div>
            <div className="four columns">
              <div>
                <img
                  className="services-pic"
                  src="images/services/methodology.jpg"
                  alt="Methodolody"
                />
              </div>
              <h3>{t("services.methodolody-title")}</h3>
              <p>{t("services.methodolody-desc")}</p>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default withTranslation()(Services);