import $ from "jquery";

export const getContentData = (fnSuccess) => {
    $.ajax({
      url: "./contentData.json",
      dataType: "json",
      cache: false,
      success: fnSuccess,
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
};
